import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
      fetchKategorisProduk(ctx, queryParams) {
          return new Promise((resolve, reject) => {
              axios
                  .get('/api/auth/kategori', { params: queryParams })
                  .then(response => resolve(response))
                  .catch(error => reject(error))
          })
      },

      fetchKategoriProduk(ctx, { id }) {
          return new Promise((resolve, reject) => {
              axios
                  .get(`/api/auth/kategori/${id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
          })
      },
      fetchLevelMember(ctx, queryParams) {
          return new Promise((resolve, reject) => {
              axios
                  .get('/api/auth/levelmember', { params: queryParams })
                  .then(response => resolve(response))
                  .catch(error => reject(error))
          })
      },
      fetchReviews(ctx, queryParams) {
          return new Promise((resolve, reject) => {
              axios
                  .get('/api/auth/review', { params: queryParams })
                  .then(response => resolve(response))
                  .catch(error => reject(error))
          })
      },
      fetchFlashSales(ctx, queryParams) {
          return new Promise((resolve, reject) => {
              axios
                  .get('/api/auth/flashsale', { params: queryParams })
                  .then(response => resolve(response))
                  .catch(error => reject(error))
          })
      },
      fetchFlashSale(ctx, { id }) {
          return new Promise((resolve, reject) => {
              axios
                  .get(`/api/auth/flashsale/${id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
          })
      },
  },
}
