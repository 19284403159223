<template>
    <div>
        <b-alert
            variant="danger"
            :show="typeof kategoriProductData !== 'object'"
        >
            <h4 class="alert-heading">
                Error fetching product data
            </h4>
            <div class="alert-body">
                No produk found with the id. Check
                <b-link
                    class="alert-link"
                    :to="{ name: 'apps-produk-list'}"
                >
                    Produk List
                </b-link>
                for other produk.
            </div>
        </b-alert>

        <template v-if="typeof kategoriProductData !== undefined">
            <!-- First Row -->
            <b-row>
                <b-col
                    cols="12"
                >
                    <view-info-card :data="kategoriProductData" />
                </b-col>
                <!--        <b-col
                          cols="12"
                          md="5"
                          xl="3"
                          lg="4"
                        >
                          <user-view-user-plan-card />
                        </b-col>-->
            </b-row>
            <b-row>
                <b-col
                    cols="12"
                >

                    <template>
                        <produk-list :kategori="kategoriProductData.Id" isWidget />
                    </template>
                </b-col>
            </b-row>

            <!--      <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <user-view-user-timeline-card />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <user-view-user-permissions-card />
                    </b-col>
                  </b-row>

                  <invoice-list />-->
        </template>

    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
    BRow, BCol, BAlert, BLink, BTabs,BTab,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import ProdukList from '@/views/apps/produk/List.vue'
import ReviewList from '@/views/master/review/List.vue'
import MasterStoreModule from '../masterStoreModule'
import ViewInfoCard from "./ViewInfoCard";

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BTabs,
        BTab,
        ViewInfoCard,
        InvoiceList,
        ProdukList
    },
    setup() {
        const kategoriProductData = ref(null)

        const KATEGORIPRODUK_APP_STORE_MODULE_NAME = 'master-kategoriproduk'

        // Register module
        if (!store.hasModule(KATEGORIPRODUK_APP_STORE_MODULE_NAME)) store.registerModule(KATEGORIPRODUK_APP_STORE_MODULE_NAME, MasterStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(KATEGORIPRODUK_APP_STORE_MODULE_NAME)) store.unregisterModule(KATEGORIPRODUK_APP_STORE_MODULE_NAME)
        })

        store.dispatch('master-kategoriproduk/fetchKategoriProduk', { id: router.currentRoute.params.id })
            .then(response => {
                kategoriProductData.value = response.data
            })
            .catch(error => {
                if (error.response.status === 404) {
                    kategoriProductData.value = undefined
                }
            })

        return {
            kategoriProductData,
        }
    },
}
</script>

<style>

</style>
