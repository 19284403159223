import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchProduks(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/auth/produk', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchProduk(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/auth/produk/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addImages(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/auth/produk/upload-images', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteImage(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/auth/produk/delete-image/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
