var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('own-table',{ref:"produkTable",attrs:{"name-table":"produk","table-columns":_vm.tableColumns,"disable-create-btn":true,"use-custom-filter":true,"sort-by":"kd_produk","sort-direction":"asc","import-excel":true,"custom-query-filter":_vm.queryFilter},on:{"searchFieldOnChange":_vm.searchOnChange},scopedSlots:_vm._u([{key:"cell(no)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.data.index + 1))])]}},{key:"cell(image_url)",fn:function(data){return [_c('b-img',{staticStyle:{"max-width":"100px"},attrs:{"fluid":"","src":("" + (data.data.item.images.length > 0 ? ((_vm.config.apiUrl) + "/" + (data.data.item.images[0].path)) : require('@/assets/images/no-img.webp')))}})]}},{key:"cell(kd_produk)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
      name: 'apps-produk-view',
      params: { id: data.data.item.kd_produk },
    }}},[_vm._v(" "+_vm._s(data.data.item.kd_produk)+" ")])],1)]}},{key:"cell(kd_kategori)",fn:function(data){return [(data.data.item.kategori)?_c('b-link',{attrs:{"to":{
      name: 'master-kategoriproduk-view',
      params: { id: data.data.item.kategori.Id },
    }}},[_vm._v(_vm._s(data.data.value)+" - "+_vm._s(data.data.item.kategori.Nama_Kategori))]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":_vm.resolveStatusActiveIcon(data.data.item.status),"size":"18"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.data.item.status == '1' ? 'Active' : 'Not Active'))])],1)]}},{key:"cell(harga)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatCurrency(data.data.item.harga))+" ")]}},{key:"import-excel-template",fn:function(){return [_c('b-button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.importExcel()}}},[_vm._v(" Import Excel ")])]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"},on:{"click":function($event){return _vm.doEdit(data.data.item.kd_produk)}}})],1)]}},{key:"customer-header",fn:function(){return [_c('b-form-group',{staticClass:"mr-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticStyle:{"width":"200px"},attrs:{"id":"tag","label":"test","placeholder":"Filter By Tag","filterable":false,"options":_vm.tagOptions,"reduce":function (e) {
      return e.name
    }},on:{"input":_vm.onInputTag},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v(" "+_vm._s(option.name)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.name)+" ")])]}}]),model:{value:(_vm.selectedTag),callback:function ($$v) {_vm.selectedTag=$$v},expression:"selectedTag"}})],1)])]},proxy:true}])})],1),_c('b-modal',{attrs:{"ok-only":""},on:{"ok":_vm.okImportHandler},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
    var ok = ref.ok;
return [_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return ok()}}},[_c('span',[_vm._v("Upload")]),(_vm.isLoading)?_c('b-spinner',{staticClass:"ml-1",attrs:{"small":""}}):_vm._e()],1)]}}]),model:{value:(_vm.showModalImport),callback:function ($$v) {_vm.showModalImport=$$v},expression:"showModalImport"}},[_c('span',[_c('a',{attrs:{"href":((_vm.config.apiUrl) + "/public/file/product_template.xls")}},[_vm._v("Download Template")]),_vm._v(" before do import")]),_c('b-form-file',{attrs:{"state":Boolean(_vm.excelFile),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.excelFile),callback:function ($$v) {_vm.excelFile=$$v},expression:"excelFile"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }