import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
/* import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue' */

export default function useList(context, kategori = null) {
  // Use toast
  /* const toast = useToast() */

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [{
    label: 'No',
    key: 'no',
  },
  { key: 'image_url', label: '' },
  { key: 'kd_produk', sortable: true, label: 'Kode produk' },
  { key: 'nama_produk', sortable: true },
  { key: 'kd_kategori', sortable: true, label: 'Kategori' },
  // { key: 'stock', sortable: true },
  // { key: 'terjual', sortable: true },
  { key: 'harga', sortable: true },
  {
    label: 'Actions',
    key: 'actions',
  },
    /* { key: 'role', sortable: true },
    {
      key: 'currentPlan',
      label: 'Plan',
      formatter: title,
      sortable: true,
    }, */
  ]
  const perPage = ref(15)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('kd_produk')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-produk/fetchProduks', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        kategori,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, total } = response.data
        callback(data)
        totalUsers.value = total
      })
      .catch(() => {
        /* toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        }) */
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusActiveIcon = role => {
    if (role === '1') return 'CheckIcon'
    if (role === '0') return 'XIcon'
    return 'UserIcon'
  }

  const doEdit = id => {
    context.root.$router.push({ name: 'apps-produk-view', params: { id } })
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    resolveStatusActiveIcon,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    doEdit,
  }
}
