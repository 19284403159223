<template>
  <div>
    <b-card>
      <own-table ref="produkTable" name-table="produk" :table-columns="tableColumns" :disable-create-btn="true"
        :use-custom-filter="true" sort-by="kd_produk" sort-direction="asc" :import-excel="true"
        :custom-query-filter="queryFilter" @searchFieldOnChange="searchOnChange">
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(image_url)="data">
          <b-img fluid
            :src="`${data.data.item.images.length > 0 ? `${config.apiUrl}/${data.data.item.images[0].path}` : require('@/assets/images/no-img.webp')}`"
            style="max-width: 100px" />
        </template>
        <template #cell(kd_produk)="data">
          <b-media vertical-align="center">
            <b-link :to="{
        name: 'apps-produk-view',
        params: { id: data.data.item.kd_produk },
      }" class="font-weight-bold d-block text-nowrap">
              {{ data.data.item.kd_produk }}
            </b-link>
          </b-media>
        </template>
        <!-- <template #cell(stock)="data">
          {{ data.data.value }} {{ data.data.item.satuan }}
        </template> -->
        <template #cell(kd_kategori)="data">
          <b-link v-if="data.data.item.kategori" :to="{
        name: 'master-kategoriproduk-view',
        params: { id: data.data.item.kategori.Id },
      }">{{ data.data.value }} -
            {{ data.data.item.kategori.Nama_Kategori }}</b-link>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <feather-icon :icon="resolveStatusActiveIcon(data.data.item.status)" size="18" class="mr-50" />
            <span class="align-text-top text-capitalize">{{
        data.data.item.status == '1' ? 'Active' : 'Not Active'
      }}</span>
          </div>
        </template>
        <template #cell(harga)="data">
          {{ formatCurrency(data.data.item.harga) }}
        </template>
        <template #import-excel-template>
          <b-button class="btn btn-warning" @click="importExcel()">
            Import Excel
          </b-button>
        </template>
        <template #cell(actions)="data">
          <span class="ml-1"><feather-icon icon="EyeIcon" @click="doEdit(data.data.item.kd_produk)" /></span>
        </template>
        <template #customer-header>
          <b-form-group class="mr-1">
            <div class="d-flex align-items-center">
              <!-- <label class="mr-1 d-sm-inline d-none">Search</label> -->
              <v-select id="tag" style="width: 200px;" v-model="selectedTag" label="test" placeholder="Filter By Tag"
                :filterable="false" :options="tagOptions" :reduce="e => {
        return e.name
      }" @input="onInputTag">
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </div>
          </b-form-group>
        </template>
      </own-table>
    </b-card>
    <b-modal v-model="showModalImport" ok-only @ok="okImportHandler">
      <span><a :href="`${config.apiUrl}/public/file/product_template.xls`">Download Template</a>
        before do import</span>
      <b-form-file v-model="excelFile" :state="Boolean(excelFile)" placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..." />
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="success" @click="ok()">
          <span>Upload</span>
          <b-spinner v-if="isLoading" small class="ml-1" />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BOverlay,
  BModal,
  BFormFile,
  BSpinner,
  BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText, formatCurrency } from '@core/utils/filter'
import OwnTable from '@/components/Table/Table.vue'
import config from '@/config/config'
import axiosIns from '@/libs/axios'
import masterStoreModule from './produkStoreModule'
import useList from './useList'
import query from 'devextreme/data/query'
import service from '@/components/Table/service'
import router from '@/router'

export default {
  components: {
    BOverlay,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    OwnTable,
    BModal,
    BFormFile,
    BSpinner,
    vSelect,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    kategori: {
      type: String,
      required: false,
    },
    perPageParam: {
      type: Number,
      required: false,
    },
  },
  methods: {
    debounceInput: _.debounce(function (e) {
      this.searchQuery = e
    }, 500),
  },
  setup(props, context) {
    const USER_APP_STORE_MODULE_NAME = 'app-produk'
    const loadTable = false
    const produkTable = ref(null)
    const filter = Object.keys(context.root.$route.query).map((el, idx) => `${el}=${context.root.$route.query[el]}`).join('&')
    const queryFilter = context.root.$route.query ? ref(`&${filter}`) : ref('')
    console.log(queryFilter.value)
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, masterStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })

    onMounted(() => {
      const { q } = context.root.$route.query
      if (q) {
        produkTable.value.customSearchModel = q
      }
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveStatusActiveIcon,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      doEdit,
    } = useList(context, props.kategori)

    const excelFile = ref(null)
    const showModalImport = ref(false)

    const isLoading = ref(false)

    const importExcel = () => {
      showModalImport.value = true
    }
    const okImportHandler = evt => {
      isLoading.value = true
      evt.preventDefault()
      const formData = new FormData()
      // console.log(excelFile)
      formData.append('uploaded_file', excelFile.value)
      axiosIns
        .post('/api/auth/produk/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          if (res.status === 200) {
            showModalImport.value = false
            isLoading.value = false
            produkTable.value.refetchData()
          }
        })
    }

    const q = ref('')
    const selectedTag = context.root.$route.query.tag ? ref(context.root.$route.query.tag) : ref('')
    const filterOnChange = () => {
      queryFilter.value = `${q.value !== '' ? `&q=${q.value}` : ''}${selectedTag.value === '' || selectedTag.value === null ? '' : `&tag=${selectedTag.value}` }`
      const query = {}
      if (q.value !== '') {
        query.q = q.value
      }

      if (selectedTag.value !== '') {
        if (selectedTag.value !== null) {
          query.tag = selectedTag.value
        }
      }
      context.root.$router.push({ name: 'apps-produk-list', query })
    }
    const searchOnChange = value => {
      // queryFilter.value = `&q=${value}`
      q.value = value
      filterOnChange()
      // context.root.$router.push({ name: 'apps-produk-list', query: { q: q.value, tag: v } })
    }

    const tagOptions = ref([])
    const onSelectedTag = (tag) => {
      queryFilter.value = `&q=${q.value}&tag=${tag.name}`
      // produkTable.value.refetchData()
    }
    const onDeselectedTag = (tag) => {
      queryFilter.value = `&q=${q.value}`
      console.log('on deselected')
    }

    const onClear = () => {
      // queryFilter.value = `&q=${q.value}`
      console.log('on clear')
    }

    const onInputTag = (v) => {
      // if (v === null) {
      //   queryFilter.value = `&q=${q.value}`
      // } else {
      //   queryFilter.value = `&q=${q.value}&tag=${v}`
      // }
      selectedTag.value = v
      filterOnChange()
      // context.root.$router.push({ name: 'apps-produk-list', query: { q: q.value, tag: v } })
    }

    const fetchTags = async (query = null) => {
      try {
        const response = await service.index({ url: 'tags', query })
        // console.log(response.data)
        tagOptions.value = response.data.data
      } catch (error) {
        console.log(error)
      }
    }

    fetchTags()

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      loadTable,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveStatusActiveIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      importExcel,
      showModalImport,
      excelFile,
      config,
      okImportHandler,
      isLoading,
      doEdit,
      searchOnChange,
      queryFilter,
      formatCurrency,
      produkTable,
      tagOptions,
      selectedTag,
      onSelectedTag,
      onDeselectedTag,
      onClear,
      onInputTag
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
